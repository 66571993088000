<template>
  <div class="examiner-main">
    <div class="content-wrapper">
      <Breadcrumb class="custom-breadcrumb" ref="breadcrumb">
        <BreadcrumbItem>考试管理</BreadcrumbItem>
        <BreadcrumbItem :to="{ path: '/exam/paperbank' }">试卷库管理</BreadcrumbItem>
        <!--        <BreadcrumbItem v-if="!$route.query['id']" :to="{ path: '/exam/list' }">查看所有考试({{ $route.query.tabLabel }})</BreadcrumbItem>-->
        <BreadcrumbItem v-if="!$route.query['id']" :to="{ path: '/exam/paperlist' }">查看所有考试({{ $route.query.tabLabel }})</BreadcrumbItem>
        <BreadcrumbItem
          v-else
          :to="{
            path: '/exam/IndividualPapers',
            query: {
              id: $route.query['id'],
              paperBankId: $route.query['paperBankId'],
              tabLabel: $route.query['tabLabel'],
              name: $route.query['name']
            }
          }"
        >
          查看所有考试({{ $route.query.tabLabel }})</BreadcrumbItem
        >
        <BreadcrumbItem>查看考试人员</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div style="display: flex;margin-top: 10px;height: calc(100% - 28px)">
      <div id="left-tree" class="container">
        <div class="container-title">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiagoufenxiao" />
          </svg>
          <span class="nowrap" style="margin-left: 5px;flex: 1 1 auto;">部门选择</span>
        </div>
        <div style="height: calc(100% - 50px);overflow: auto">
          <div style="text-align: left;margin: 20px 0 0 26px">
            <!--          <span style="font-size: 14px;font-weight: 400;cursor: pointer" @click="getCurrentDepartmentId(chooseDepartmentId)">所有部门</span>-->
          </div>
          <el-tree
            id="tree-container"
            v-if="treeData.length > 0"
            :current-node-key="treeData[0].departmentId"
            highlight-current
            ref="tree"
            node-key="departmentId"
            @current-change="getCurrentDepartmentId"
            :props="defaultProps"
            :data="treeData"
            :expand-on-click-node="false"
          >
            <div style="width: 100%;overflow: hidden" slot-scope="{ node, data }">
              <el-tooltip effect="dark" :disabled="node.label.length < 8" :content="node.label" placement="top">
                <span>{{ node.label }}</span>
              </el-tooltip>
            </div>
          </el-tree>
        </div>
      </div>
      <div id="right-content">
        <div class="search-container">
          <Input
            class="search-bar"
            search
            placeholder="请输入员工姓名"
            v-model="searchContent"
            @on-search="getExaminerByExam(0)"
            @keyup.native.enter="getExaminerByExam(0)"
          />
          <div>
            <el-button class="button-wrapper" size="mini" @click="showRetry" type="primary">发布重考</el-button>
            <el-button class="button-wrapper" size="mini" :loading="importIng" @click="importAllReports" type="primary">导出员工考试报告</el-button>
            <el-button @click="examPInfo" size="mini" type="primary">导出员工信息</el-button>
          </div>
        </div>
        <div class="table-wrapper">
          <Table :columns="columns" :data="tableData" :loading="showLoading">
            <template slot-scope="{ row, index }" slot="realName">
              <div style="display: flex;align-items: center;" :title="row.realName">
                <img
                  width="35"
                  height="35"
                  :class="row.avatar ? 'imgCircle' : ''"
                  style=""
                  :src="row.avatar || require('@assets/defaultAvatar.svg')"
                  alt=""
                />
                <span style="margin-left: 15px;width: 80px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ row.realName }}</span>
              </div>
            </template>
            <template slot-scope="{ row, index }" slot="departmentName">
              <div class="nowrap" :title="row.departmentName">
                {{ row.departmentName }}
              </div>
            </template>
            <template slot-scope="{ row, index }" slot="postName">
              <div class="nowrap" :title="row.postName">{{ row.postName }}</div>
            </template>
            <template slot-scope="{ row }" slot="score">
              <span :style="{ color: row.pass ? '#5ABC50' : '#E66B42' }" v-if="row.score">{{ row.score }}</span>
              <span style="color: #E66B42" v-else>0</span>
            </template>
            <template slot-scope="{ row, index }" slot="duration">
              <span v-if="row.answerPaperId" :title="row.duration ? getTime(row.duration) : '小于60秒'">{{
                row.duration ? getTime(row.duration) : '小于60秒'
              }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="action">
              <div v-if="row.answerPaperId" class="row-action-container">
                <i title="下载报告" style="cursor: pointer;font-size: 18px" class="el-icon-download" @click.stop="downloadExamReport(row)" />
                <i title="导入报告" style="cursor: pointer;font-size: 18px" class="el-icon-upload2" @click.stop="exportExamFile(row)" />
                <Icon
                  title="考试详情"
                  aria-hidden="true"
                  style="cursor: pointer;font-size: 20px"
                  type="ios-eye-outline"
                  @click.stop="handleSee(row)"
                />
                <i title="个人报告" style="cursor: pointer;font-size: 18px" class="el-icon-document" @click.stop="myReport(row)" />
              </div>
            </template>
          </Table>
          <div class="page">
            <CommonPage :total="total" @changeSize="changePageSize" @changePage="changePage" ref="page" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog class="uploadDialog" :visible.sync="IsImportExam" width="45%" title="上传个人考试报告">
      <p style="margin-bottom: 15px;">文件</p>
      <el-upload class="upload-demo" drag action="" :before-upload="uploadFile">
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <Tag v-if="file" style="width: 192px" type="dot" closable color="primary" @on-close="file = null">{{ file.name }}</Tag>
      <div class="footer" style="display: flex;justify-content: flex-end;margin: 15px 0;">
        <el-button size="mini" @click="IsImportExam = false">取消</el-button>
        <el-button size="mini" :disabled="!canAble" type="primary" @click="uploadSerFile(url)">确定</el-button>
      </div>
    </el-dialog>
    <!--    重考弹窗-->
    <el-dialog class="deUsersModal" :visible.sync="retryModal" :close-on-click-modal="false" title="选择用户">
      <el-tabs v-model="activeIndex" style="margin-bottom: 20px;">
        <el-tab-pane label="参考人员" name="1">
          <el-table
            ref="allTable"
            :data="allData"
            style="width: 100%"
            :row-key="getRowKeys"
            border
            height="500"
            v-if="activeIndex === '1'"
            @selection-change="handleSelect"
          >
            <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
            <el-table-column show-overflow-tooltip label="工号" align="center" prop="jobNumber" />
            <el-table-column show-overflow-tooltip label="岗位" align="center" prop="postName" />
            <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="未及格人员" name="2">
          <el-table
            ref="failTable"
            :data="failData"
            style="width: 100%"
            :row-key="getRowKeys"
            border
            height="500"
            v-if="activeIndex === '2'"
            @selection-change="handleSelect"
          >
            <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
            <el-table-column show-overflow-tooltip label="学号" align="center" prop="jobNumber" />
            <el-table-column show-overflow-tooltip label="专业" align="center" prop="postName" />
            <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="未参考人员" name="3">
          <el-table
            ref="unJoinTable"
            :data="unJoinData"
            style="width: 100%"
            :row-key="getRowKeys"
            border
            height="500"
            v-if="activeIndex === '3'"
            @selection-change="handleSelect"
          >
            <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
            <el-table-column show-overflow-tooltip label="学号" align="center" prop="jobNumber" />
            <el-table-column show-overflow-tooltip label="专业" align="center" prop="postName" />
            <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="所有人员" name="4">
          <div style="height: 500px;display: flex;justify-content: space-between;">
            <div style="width: 43%;">
              <div class="container-title">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-jiagoufenxiao" />
                </svg>
                <span class="nowrap" style="margin-left: 5px;flex: 1 1 auto;">部门选择</span>
              </div>
              <div style="height: calc(100% - 50px);overflow: auto">
                <div style="text-align: left;margin: 20px 0 0 26px">
                  <!--          <span style="font-size: 14px;font-weight: 400;cursor: pointer" @click="getCurrentDepartmentId(chooseDepartmentId)">所有部门</span>-->
                </div>
                <el-tree
                  id="retry-tree-container"
                  v-if="treeData.length > 0"
                  :current-node-key="treeData[0].departmentId"
                  highlight-current
                  ref="tree"
                  node-key="departmentId"
                  @current-change="handleDepartmentId"
                  :props="defaultProps"
                  :data="treeData"
                  :expand-on-click-node="false"
                >
                  <div style="width: 100%;overflow: hidden" slot-scope="{ node, data }">
                    <el-tooltip effect="dark" :disabled="node.label.length < 8" :content="node.label" placement="bottom-start">
                      <div>{{ node.label }}</div>
                    </el-tooltip>
                  </div>
                </el-tree>
              </div>
            </div>
            <div style="width: 55%">
              <div style="display: flex;justify-content: flex-start;margin-bottom: 15px">
                <el-input
                  style="width: 300px;"
                  size="small"
                  placeholder="请输入姓名(回车搜索)"
                  v-model="retryKeyword"
                  @keyup.native.enter="searchByKeyWord"
                />
              </div>
              <div class="overStyle" style="max-height: 300px; overflow-y: auto">
                <el-table
                  ref="departmentTable"
                  :data="departmentTableData"
                  style="width: 100%"
                  :row-key="getRowKeys"
                  border
                  v-if="activeIndex === '4'"
                  @selection-change="handleSelect"
                >
                  <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
                  <el-table-column show-overflow-tooltip label="工号" align="center" prop="jobNumber" />
                  <el-table-column show-overflow-tooltip label="岗位" align="center" prop="postName" />
                  <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
                </el-table>
              </div>
              <div style="margin-top: 20px;display: flex;justify-content: space-between">
                <Page size="small" show-total @on-change="handlePageNum" :total="departmentTotal"> </Page>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <el-button size="mini" @click="retryExam" type="primary">确定</el-button>
      <!--        </div>-->
      <!--      </div>-->
    </el-dialog>
  </div>
</template>

<script>
import examApi from '@api/exam'
import examReport from '@api/examReport'
import CommonPage from '@/components/common/CommonPage'
import util from '../../../../util/util'
import configApi from '../../../../config/config'
import fileApi from '../../../../api/file'
import oss from '../../../../api/oss'
import exspanRow from './exspanRow'
import departmentApi from '../../../../api/department'
import staff from '../../../../api/staff'
export default {
  name: 'Examiner',
  components: { CommonPage, exspanRow },
  data() {
    return {
      canAble: false,
      defaultProps: {
        label: 'name',
        children: 'children'
      },
      treeData: [],
      showLoading: true,
      IsImportExam: false,
      importIng: false,
      searchContent: '',
      total: 100,
      page: 0,
      size: 10,
      examId: null,
      chooseDepartmentId: {
        departmentId: ''
      },
      file: null,
      departmentId: '',
      tableData: [],
      row: null,
      columns: [
        {
          type: 'expand',
          width: 35,
          render: (h, params) => {
            return h(exspanRow, {
              props: {
                row: params.row
              }
            })
          }
        },
        // {
        //   type: 'selection',
        //   width: 60,
        //   align: 'center'
        // },
        {
          title: '姓名',
          width: 150,
          slot: 'realName'
        },
        {
          title: '工号',
          key: 'jobNumber',
          width: 120,
          ellipsis: true,
          tooltip: true
        },
        {
          title: '部门名称',
          key: 'departmentName',
          slot: 'departmentName'
        },
        {
          title: '岗位名称',
          key: 'postName',
          slot: 'postName'
        },
        {
          title: '分数',
          slot: 'score'
        },
        // {
        //   title: '开始时间',
        //   slot: 'startTime',
        //   sortable: true
        // },
        // {
        //   title: '结束时间',
        //   slot: 'submitTime',
        //   sortable: true
        // },
        {
          title: '用时',
          slot: 'duration'
        },
        {
          title: '操作',
          slot: 'action',
          align: 'center',
          width: 150
        }
      ],
      retryModal: false,
      activeIndex: '1',
      getRowKeys(row) {
        return row.userId
      },
      userIds: [],
      allData: [],
      failData: [],
      unJoinData: [],
      retryPage: 0,
      retrySize: 10,
      retryParentId: 0,
      retryDepartmentId: '',
      departmentTableData: [],
      departmentTotal: 0,
      retryKeyword: '',
      searchUser: {
        pageParamForm: {
          pageNumber: 0,
          pageSize: 10
        },
        sortCriteria: {},
        userCriteria: {
          email: '',
          phone: '',
          realName: null,
          status: 0
        }
      }
    }
  },
  watch: {
    IsImportExam(val) {
      if (val === false) {
        this.canAble = false
      }
    }
  },
  created() {
    this.getExaminerByExam()
    this.getDepartments()
  },
  beforeRouteEnter(to, from, next) {
    // 页面进入前判定数据是否来自详情，来获取保留数据
    if (from.path === '/exam/myReport') {
      to.meta.keepAlive = true
    } else {
      to.meta.keepAlive = false
    }
    next()
  },
  methods: {
    showRetry() {
      this.getRetryExaminerByExam()
      this.retryModal = true
    },
    handleSelect(val) {
      this.userIds = []
      val.forEach(v => {
        this.userIds.push(v.userId)
      })
    },
    handleDepartmentId(nodeData) {
      this.retryKeyword = ''
      this.retryDepartmentId = nodeData.departmentId
      this.searchByKeyWord()
    },
    getUserById() {
      departmentApi.getDepartmentStaff(this.retryDepartmentId, this.retryPage, this.retrySize).then(res => {
        this.departmentTableData = res.res.data
        this.departmentTotal = res.res.total
      })
    },
    handlePageNum(val) {
      this.searchUser.pageParamForm.pageNumber = val - 1
      this.retryPage = val - 1
      if (this.retryKeyword === null || this.retryKeyword.trim() === '') {
        this.searchUser.userCriteria.realName = this.retryKeyword
        staff.searchStaffs(this.searchUser).then(res => {
          this.departmentTableData = res.res.data
          this.departmentTotal = res.res.total
        })
      } else {
        this.searchUser.pageParamForm.pageNumber = val - 1
        this.searchByKeyWord()
      }
    },
    searchByKeyWord() {
      this.searchUser.departmentId = this.retryParentId === 0 ? '' : this.retryDepartmentId
      this.searchUser.keyword = this.retryKeyword
      this.searchUser.status = 0
      staff.searchStaffs(this.searchUser, this.searchUser.pageParamForm.pageNumber, this.searchUser.pageParamForm.pageSize).then(res => {
        this.departmentTableData = res.res.data
        this.departmentTotal = res.res.total
      })
    },
    retryExam() {
      examApi.makeRepeatExam(this.$route.query.examId, this.userIds).then(res => {
        if (res.res) {
          this.$message.success('发布重考成功!')
          if (this.activeIndex === '1') {
            this.$refs.allTable.clearSelection()
          } else if (this.activeIndex === '2') {
            this.$refs.failTable.clearSelection()
          } else if (this.activeIndex === '3') {
            this.$refs.unJoinTable.clearSelection()
          } else if (this.activeIndex === '4') {
            this.$refs.departmentTable.clearSelection()
          }
          this.retryModal = false
          this.getExaminerByExam()
        }
      })
    },
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    uploadSerFile(file) {
      let form = {
        answerPaperId: this.row.answerPaperId,
        examId: this.examId,
        jobNumber: this.row.jobNumber || null, // 工号
        reportType: 1, // 0 =》 试卷报告 1=》 个人报告
        reportUrl: file,
        userId: this.row.userId
      }
      examApi.importExam(form).then(res => {
        if (res.code === 0) {
          this.message('成功', 'success')
          this.IsImportExam = false
          this.getExaminerByExam()
        } else {
        }
      })
    },
    exportExamFile(row) {
      this.row = row
      this.IsImportExam = true
      // console.log(row)
    },
    uploadFile(file) {
      let _this = this
      this.file = file
      if (window.uploadUrl) {
        // 局域网
        let formData = new FormData()
        formData.append('file', file)
        fileApi.uploadServeFile(formData).then(res => {
          if (res.code === 0) {
            this.url = res.res
            this.canAble = true
          } else {
          }
        })
      } else {
        // oss.
        oss.upType().then(res => {
          if (res.data.code === 0) {
            oss.getQuestionFileToken(file.name).then(data => {
              let fData = data.data.res
              const formData = new FormData()
              formData.append('key', fData.dir)
              formData.append('OSSAccessKeyId', fData.accessId)
              formData.append('policy', fData.policy)
              formData.append('Signature', fData.signature)
              formData.append('file', file)
              fetch(`https://${fData.host}`, {
                method: 'POST',
                body: formData
              }).then(() => {
                _this.url = `https://${fData.host}/${fData.dir}`
                _this.canAble = true
              })
            })
          }
        })
      }
      return false
    },
    downloadExamReport(row) {
      // console.log('row')
      // console.log(row)
      let form = {
        examId: Number(this.examId),
        jobNumber: row.jobNumber || null, // 工号
        reportType: 1, // 0 =》 试卷报告 1=》 个人报告
        userId: row.userId
      }
      examApi.getExamReport(form).then(res => {
        if (res.res === '') {
          return this.message('未导入报告或链接已失效', 'warning')
        }
        window.location.href = res.res
      })
    },
    myReport(row) {
      // 个人报告
      this.$router.push({
        path: '/exam/myReport',
        query: {
          userId: row.userId,
          id: this.examId,
          anPaId: row.answerPaperId,
          examName: this.$route.query['name'],
          tabLabel: this.$route.query.tabLabel,
          page: this.page // 缓存需要
        }
      })
    },
    importAllReports() {
      this.importIng = true
      examReport
        .getExamReportById(this.examId)
        .then(res => {
          if (res.res === '该考试无有效报告，无法生成') {
            return
          }
          // 创建隐藏的可下载链接
          let eleLink = document.createElement('a')
          eleLink.download = res.res.substring(res.res.lastIndexOf('/') + 1)
          eleLink.style.display = 'none'
          // 字符内容转变成blob地址
          eleLink.href = res.res
          // 触发点击
          document.body.appendChild(eleLink)
          eleLink.click()
          // 然后移除
          document.body.removeChild(eleLink)
        })
        .catch(() => {})
        .finally(() => {
          this.importIng = false
        })
    },
    examPInfo() {
      examApi.exportPeoInfo(this.examId).then(res => {
        util.exportExamPeoInfo(res, '考试人员')
      })
    },
    handleSee(row) {
      this.$router.push({
        path: '/exam/ExamDetail',
        query: {
          tabLabel: this.$route.query.tabLabel,
          examId: this.$route.query.examId,
          submitTime: row.submitTime,
          duration: row.duration,
          score: row.score,
          userId: row.userId,
          paperBankId: row.paperBankId,
          id: row.paperId,
          exam: true,
          name: row.name,
          IsAnswerExam: true,
          IsExamLibrary: true
        }
      })
    },
    getCurrentDepartmentId(nodeData) {
      this.departmentId = nodeData.departmentId
      this.getExaminerByExam()
    },
    getTime(time) {
      let theTime = parseInt(time * 60) // 需要转换的时间秒
      let theTime1 = 0 // 分
      let theTime2 = 0 // 小时
      let theTime3 = 0 // 天
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
          if (theTime2 > 24) {
            //大于24小时
            theTime3 = parseInt(theTime2 / 24)
            theTime2 = parseInt(theTime2 % 24)
          }
        }
      }
      let result = ''
      if (theTime > 0) {
        result = '' + parseInt(theTime) + '秒'
      }
      if (theTime1 > 0) {
        result = '' + parseInt(theTime1) + '分钟' + result
      }
      if (theTime2 > 0) {
        result = '' + parseInt(theTime2) + '小时' + result
      }
      if (theTime3 > 0) {
        result = '' + parseInt(theTime3) + '天' + result
      }
      return result
    },
    getDepartments() {
      this.treeData = []
      this.$store.dispatch('department/getDepartmentTree').then(res => {
        let tree = res.res
        this.treeData.push(tree)
        // this.retryDepartmentId = 0
        this.searchByKeyWord()
      })
    },
    appendTreeNode(node, data) {
      for (var i = 0, len = node.children.length; i < len; i++) {
        data.push({
          title: node.children[i].name,
          selected: false,
          expand: false,
          children: [],
          detail: node.children[i]
        })
        if (node.children[i].children.length) {
          this.appendTreeNode(node.children[i], data[data.length - 1].children)
        }
      }
    },
    getRetryExaminerByExam() {
      this.failData = []
      this.unJoinData = []
      examApi.getExamineesByExamId(this.examId, this.page, 9999, this.departmentId, '').then(res => {
        this.allData = res.res.data
        this.allData.forEach(item => {
          if (item.hasOwnProperty('pass') && !item.pass) {
            this.failData.push(item)
          }
          if (!item.hasOwnProperty('answerPaperId')) {
            this.unJoinData.push(item)
          }
        })
      })
    },
    getExaminerByExam(data) {
      if (data === 0) {
        this.page = 0
        this.size = 10
      }
      this.failData = []
      examApi
        .getExamineesByExamId(this.examId, this.page, this.size, this.departmentId, this.searchContent)
        .then(res => {
          this.total = res.res.total
          this.tableData = res.res.data
          this.allData = res.res.data
          this.tableData.forEach(item => {
            if (item.hasOwnProperty('pass') && !item.pass) {
              this.failData.push(item)
            }
            if (!item.hasOwnProperty('answerPaperId')) {
              this.unJoinData.push(item)
            }
          })
          this.showLoading = false
        })
        .catch(() => {
          this.showLoading = false
        })
    },
    changePage(val) {
      this.page = val - 1
      this.getExaminerByExam()
      this.$refs.page.resetPage()
    },
    changePageSize(val) {
      this.size = val
      this.getExaminerByExam()
      this.$refs.page.resetPage()
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../../theme/variables';
.examiner-main {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  height: 100%;
}
#left-tree {
  max-width: 303px;
  /*height: 804px;*/
  background-color: #fff;
}
.container-title {
  .icon {
    width: 17px;
    margin-left: 23px;
    margin-right: 12px;
    height: 17px;
    background: rgba(0, 0, 0, 0);
    opacity: 1;
  }
  width: 100%;
  height: 49.53px;
  background: rgba(228, 233, 239, 1);
  padding: 16px 37px 16px 10px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: rgba(58, 78, 100, 1);
  display: flex;
  align-items: center;
}
.el-tree {
  margin-left: 10px;
  /deep/ .is-expanded {
  }
  /deep/ .el-tree-node__content {
    text-align: left;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 21px;
    .el-tree-node__expand-icon {
      margin-left: 6px;
    }
    .el-icon-caret-right:before {
      font-size: 20px;
      font-weight: bold;
      color: #3a4e64;
    }
    .is-leaf:before {
      color: transparent !important;
      cursor: default !important;
    }
  }
  /deep/ .is-current > .el-tree-node__content > .el-tree-node__label {
    color: #6a8abe;
  }
}
#right-content {
  flex: auto;
  flex-direction: column;
  padding: 0 20px;
  background-color: @layout-body-background;
  overflow: auto;
  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-right: 20px;
    height: 55px;
    box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);
    .button-wrapper {
      ::v-deep i {
        font-size: 10px !important;
      }
    }
    .search-bar {
      float: left;
      width: 330px;
      margin: -5px 0 0 50px;

      ::v-deep .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        &:focus {
          box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
        }
      }
    }

    @media screen and (max-width: 1440px) {
      .search-bar {
        margin-left: 10px;
        width: 250px;
      }
    }
  }
  .row-action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 18px;
    }
  }
  .row-action-container * + * {
    margin-left: 10px;
  }
  .table-wrapper {
    flex: auto;
    /*margin-left: 20px;*/
    border-radius: 4px;
    padding: 0;
    height: calc(100% - 55px);
    /*.ivu-table-wrapper {*/
    /*  /deep/ .ivu-table-header {*/
    /*    span {*/
    /*      font-size: 16px;*/
    /*      font-family: Source Han Sans CN;*/
    /*      font-weight: 400;*/
    /*      line-height: 28px;*/
    /*      color: #3a4e64;*/
    /*      opacity: 1;*/
    /*    }*/
    /*  }*/
    /*  /deep/ .ivu-table-body {*/
    /*    span {*/
    /*      font-size: 14px;*/
    /*      font-family: Source Han Sans CN;*/
    /*      font-weight: 300;*/
    /*      line-height: 25px;*/
    /*      color: #5e6470;*/
    /*    }*/
    /*  }*/
    /*}*/
    .page {
      padding: 20px;
    }
  }
}
.imgCircle {
  border: 1px solid #dedede;
  border-radius: 50%;
}
</style>
